import VideoTypeCard from './VideoTypeCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import VideoTypeListUtil,{IVideoTypeListDataObj} from './videoTypeListUtil';
export default defineComponent ({
    name: 'VideoTypeList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IVideoTypeListDataObj=reactive<IVideoTypeListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: VideoTypeCard,
                modelMethod: utils.ToolsProviderApi.buildUrl('/videoType/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new VideoTypeListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            //找到需要格式化的列

            return options;
        }

        return{
            ...toRefs(dataObj),formatPageInfo
        }
    }
});